var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"cover container"}),_c('div',{staticClass:"mx-auto container"},[_vm._m(0),_c('div',{staticClass:"profile-body"},[_vm._m(1),_c('div',{staticClass:"body-bio"},[_c('h2',[_vm._v("ABOUT THE ROVER")]),_c('hr'),_c('div',{staticClass:"foobar"},[_c('p',{staticClass:"foo"},[_vm._v("Biography:")]),_c('p',{staticClass:"bar"},[_vm._v("Nicknamed \"Oppy\", the MER-B or MER-1 rover Opportunity was a robotic rover "),_c('router-link',{attrs:{"to":"/Spirit"}},[_vm._v("(and sibling to Spirit)")]),_vm._v(" that was active on Mars from January 2004 to June 2018 when we lost contact with it. The general consensus is that there was too much build up on its solar panels, causing a lack of power to the rover, mission was declared complete on February 13th of 2019.")],1)]),_vm._m(2),_vm._m(3),_vm._m(4)])]),_c('Quote',{attrs:{"message":"We've cleared two of the big hurdles, building both spacecraft [Spirit & Opportunity] and launching them... now we're coming up on a third, getting them safely onto the ground.","author":"Peter Theisinger","significance":"Former Project Manager at JPL for the Mars Exploration Rover Project"}}),_c('div',{staticClass:"mt-4"},[_c('h2',[_vm._v("Rover Photo Feed")]),_c('p',[_vm._v("Photos taken on Earth date "+_vm._s(_vm.results.photos[0].earth_date)+", come back tomorrow for a new set!")])]),_c('div',{staticClass:"profile-feed"},_vm._l((_vm.results.photos),function(result,index){return _c('a',{key:index,attrs:{"href":result.img_src,"target":"_blank","rel":"noopener"}},[_c('VLazyImage',{staticClass:"img",attrs:{"src":result.img_src,"alt":'Photo id#' + result.id + ' taken by Opportunity Rover in the Meridiani Planum on Mars'}})],1)}),0),_vm._m(5)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"profile-head"},[_c('div',{staticClass:"bio"},[_c('img',{staticClass:"profile-image",attrs:{"src":require("@/assets/rover/OPPY.png"),"alt":"Rocket Downrange Rover profile image for the MER-B 'Opportunity' Mars Rover"}}),_c('div',{staticClass:"name"},[_c('h1',{staticClass:"roverName"},[_vm._v("Opportunity")]),_c('p',{staticClass:"roverLocation"},[_c('i',{staticClass:"fas fa-map-marker-alt mr-2"}),_vm._v(" Meridiani Planum, Mars")])])]),_c('div',{staticClass:"stats"},[_c('div',{staticClass:"stats-item"},[_c('p',{staticClass:"stats-status"},[_vm._v("COMPLETE")]),_c('p',{staticClass:"small"},[_vm._v("Status")])]),_c('div',{staticClass:"stats-item"},[_c('p',{staticClass:"stats-status"},[_vm._v("5,352 (5,498)")]),_c('p',{staticClass:"small"},[_vm._v("SOLs (DAYs)")])]),_c('div',{staticClass:"stats-item"},[_c('p',{staticClass:"stats-status"},[_vm._v("28.06 (45.16)")]),_c('p',{staticClass:"small"},[_vm._v("MILES (KM)")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"body-3d"},[_c('iframe',{attrs:{"src":"https://mars.nasa.gov/gltf_embed/24883","width":"100%","height":"100%","frameborder":"0"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"foobar"},[_c('p',{staticClass:"foo"},[_vm._v("Launch Date:")]),_c('p',{staticClass:"bar"},[_vm._v("July 8th, 2003")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"foobar"},[_c('p',{staticClass:"foo"},[_vm._v("Landing Date:")]),_c('p',{staticClass:"bar"},[_vm._v("January 25th, 2004")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"foobar"},[_c('p',{staticClass:"foo"},[_vm._v("Rover Cost:")]),_c('p',{staticClass:"bar"},[_vm._v("$400 million")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-3"},[_c('hr'),_c('p',{staticClass:"text-center"},[_vm._v(" For more information on this rover, or other missions, "),_c('a',{attrs:{"href":"https://nasa.gov","target":"_blank","rel":"noopener"}},[_vm._v("visit NASA's official website")])])])
}]

export { render, staticRenderFns }
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"cover container"}),_c('div',{staticClass:"mx-auto container"},[_vm._m(0),_c('div',{staticClass:"profile-body"},[_vm._m(1),_c('div',{staticClass:"body-bio"},[_c('h2',[_vm._v("ABOUT THE ROVER")]),_c('hr'),_c('div',{staticClass:"foobar"},[_c('p',{staticClass:"foo"},[_vm._v("Biography:")]),_c('p',{staticClass:"bar"},[_vm._v("The MER-A rover Spirit was a robotic rover "),_c('router-link',{attrs:{"to":"/Opportunity"}},[_vm._v("(and sibling to Opportunity)")]),_vm._v(" that was active on Mars from January 2004 to June 2010 when we lost contact with it. The general consensus was that after completing a number of scientific experiments on martian soil, the rover became stuck in soft sand. It continued to do studies until contact was lost.")],1)]),_vm._m(2),_vm._m(3),_vm._m(4)])]),_c('Quote',{attrs:{"message":"The risk is real, but so is the potential reward of using these advanced rovers to improve our understanding of how planets work.","author":"Dr. Ed Weiler","significance":"Former Associate Administrator for space science at NASA Headquarters"}}),_c('div',{staticClass:"mt-4"},[_c('h2',[_vm._v("Rover Photo Feed")]),_c('p',[_vm._v("Photos taken on Earth date "+_vm._s(_vm.results.photos[0].earth_date)+", come back tomorrow for a new set!")])]),_c('div',{staticClass:"profile-feed"},_vm._l((_vm.results.photos),function(result,index){return _c('a',{key:index,attrs:{"href":result.img_src,"target":"_blank","rel":"noopener"}},[_c('VLazyImage',{staticClass:"img",attrs:{"src":result.img_src,"alt":'Photo id#' + result.id + ' taken by Spirit Rover in the Gusev Crater on Mars'}})],1)}),0),_vm._m(5)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"profile-head"},[_c('div',{staticClass:"bio"},[_c('img',{staticClass:"profile-image",attrs:{"src":require("@/assets/rover/SPIRIT.png"),"alt":"Rocket Downrange Rover profile image for the MER-A 'Spirit' Mars Rover"}}),_c('div',{staticClass:"name"},[_c('h1',{staticClass:"roverName"},[_vm._v("Spirit")]),_c('p',{staticClass:"roverLocation"},[_c('i',{staticClass:"fas fa-map-marker-alt mr-2"}),_vm._v(" Gusev Crater, Mars")])])]),_c('div',{staticClass:"stats"},[_c('div',{staticClass:"stats-item"},[_c('p',{staticClass:"stats-status"},[_vm._v("COMPLETE")]),_c('p',{staticClass:"small"},[_vm._v("Status")])]),_c('div',{staticClass:"stats-item"},[_c('p',{staticClass:"stats-status"},[_vm._v("1,892 (1,944)")]),_c('p',{staticClass:"small"},[_vm._v("SOLs (DAYs)")])]),_c('div',{staticClass:"stats-item"},[_c('p',{staticClass:"stats-status"},[_vm._v("4.8 (7.73)")]),_c('p',{staticClass:"small"},[_vm._v("MILES (KM)")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"body-3d"},[_c('iframe',{attrs:{"src":"https://mars.nasa.gov/gltf_embed/24883","width":"100%","height":"100%","frameborder":"0"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"foobar"},[_c('p',{staticClass:"foo"},[_vm._v("Launch Date:")]),_c('p',{staticClass:"bar"},[_vm._v("June 10th, 2003")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"foobar"},[_c('p',{staticClass:"foo"},[_vm._v("Landing Date:")]),_c('p',{staticClass:"bar"},[_vm._v("January 4th, 2004")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"foobar"},[_c('p',{staticClass:"foo"},[_vm._v("Rover Cost:")]),_c('p',{staticClass:"bar"},[_vm._v("$400 million")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-3"},[_c('hr'),_c('p',{staticClass:"text-center"},[_vm._v(" For more information on this rover, or other missions, "),_c('a',{attrs:{"href":"https://nasa.gov","target":"_blank","rel":"noopener"}},[_vm._v("visit NASA's official website")])])])
}]

export { render, staticRenderFns }